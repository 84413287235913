export const appHead = {"meta":[{"charset":"utf-16"},{"name":"深圳市图达科技","content":"华美云"},{"name":"viewport","content":"width=device-width, initial-scale=1"}],"link":[{"rel":"icon","type":"image/png","href":"https://huamei2001.oss-cn-shenzhen.aliyuncs.com/icons/%E5%8D%8E%E7%BE%8E%E4%BA%91logo.png"},{"rel":"manifest","type":"application/manifest+json","href":"/app.webmanifest"},{"rel":"apple-touch-startup-image","type":"image/png","href":"https://huamei2001.oss-cn-shenzhen.aliyuncs.com/icons/%E5%8D%8E%E7%BE%8E%E4%BA%91logo.png"}],"style":[],"script":[],"noscript":[],"charset":"utf-16","viewport":"width=500, initial-scale=1","title":"华美云"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false