export const getPageContentOverride = async (
    path : string,
) => {
    if(path.match(/内容文件\/(\d+\.)+建设\/.+华美服务@.+/) || 
        path.match(/内容文件\/(\d+\.)+建设\/.+华美服务@.+/)){
            const sp = path.split("华美服务@")
            const serviceType = sp[1]
            const pdfPath = `https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/华美服务介绍/${serviceType}.pdf`
        return {
            type: "pdf",
            data: [{
                file_url: pdfPath,
            }]
        }
    }
}