<template>
    <div class="__watermark_wrap">
        <div class="watermark_row" v-for="(col, colInd) in Array(columnCount)" :key="colInd">
            <div class="__watermark_text"
            v-for="(row, rowInd) in Array(rowCount)" :key="rowInd"
            >{{ text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        }
    },
    setup() {
        const columnCount = ref(1)
        const rowCount = ref(1)

        const getTextWidth = () => {
            const textEl = document.querySelector(".__watermark_text");
            if(!textEl) return 0;
            const box = textEl.getBoundingClientRect();
            const width = box.width;
            return width;
        }
        const getTextHeight = () => {
            const textEl = document.querySelector(".__watermark_text");
            if(!textEl) return 0;
            const box = textEl.getBoundingClientRect();
            const height = box.height;
            return height;
        }
        const adjustColumns = () => {
            columnCount.value = Math.ceil(window.innerWidth / getTextWidth());
        }
        const adjustRows = () => {
            rowCount.value = Math.ceil(window.innerHeight / getTextHeight());
        }
        const adjustWatermark = () => {
            adjustColumns();
            adjustRows();
        }

        onMounted(adjustWatermark)
        window.addEventListener('resize', ()=>{
            nextTick(adjustWatermark)
        })
        return {
            columnCount,
            rowCount
        }
    },
};
</script>

<style scoped>
.__watermark_wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 100;
    display: flex;
    flex-direction: row;
}

.__watermark_text {
    position: relative;
    white-space: nowrap;
    padding: 0px 2rem 2rem 0px;
    font-size: smaller;
    font-weight: lighter;
    color: rgba(169, 169, 169, 0.15);
    pointer-events: none;
    transform: rotate(-6deg);
}
</style>
