import "ant-design-vue/dist/antd.css"
import {
    Input,
    Select,
    Dropdown,
    Cascader,
    AutoComplete,
    Menu,
} from 'ant-design-vue'

export default defineNuxtPlugin(nuxtApp => {
    // Doing something with nuxtApp
    nuxtApp.vueApp
    .use(Input)
    .use(Cascader)
    .use(Select)
    .use(Dropdown)
    .use(AutoComplete)
    .use(Menu)
})
  