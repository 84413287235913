export default [
    "柏悦",
    "君悦",
    "凯悦",
    "凯悦嘉轩",
    "凯悦嘉寓",
    "Hyatt Studios",
    "逸扉",
    "Miraval",
    "阿丽拉",
    "安达仕",
    "桐森",
    "Dream Hotels",
    "凯悦尚萃",
    "凯悦嘉荟",
    "凯悦臻选",
    "凯悦悠选",
    "凯悦尚选",
    "凯悦乐家",
    "凯悦奇乐",
    "Secrets Resorts & Spas",
    "Breathless Resorts & Spas",
    "Dreams Resorts & Spas",
    "丽思卡尔顿",
    "瑞吉",
    "JW万豪",
    "丽思卡尔顿隐世精品",
    "宝格丽",
    "艾迪逊",
    "W酒店",
    "豪华精选",
    "万豪",
    "喜来登",
    "德尔塔",
    "万豪行政公寓（长住）",
    "威斯汀",
    "傲途格",
    "万丽",
    "艾美",
    "DESIGN HOTEL",
    "臻品之选",
    "万怡",
    "福朋喜来登",
    "万枫",
    "AC",
    "雅乐轩",
    "Moxy",
    "源宿（长住）",
    "莱佛士",
    "东方快车",
    "索菲特传奇",
    "费尔蒙",
    "索菲特",
    "铂翎汇臻选",
    "德朗诺",
    "世莱仕",
    "SO",
    "Rixos",
    "铂尔曼",
    "瑞士",
    "瑞享",
    "美爵",
    "美憬阁",
    "梦卓恩",
    "21c Museum",
    "25 Hours",
    "晗代",
    "the Hoxton",
    "Adagio Premium",
    "诺富特",
    "美居",
    "翰悦阁",
    "MAMA",
    "芮族",
    "宜必思",
    "宜必思尚品",
    "宜必思快捷",
    "JO&JOE",
    "六善",
    "丽晶",
    "洲际",
    "洲至奢选",
    "金普顿",
    "英迪格",
    "voco",
    "华邑",
    "皇冠假日",
    "逸衡",
    "假日",
    "智选假日",
    "avid",
    "Atwell Suites",
    "Staybridge Suites",
    "Holiday Inn Club Vacations",
    "Candlewood Suites",
    "华尔道夫",
    "LXR",
    "康莱德",
    "嘉悦里",
    "格芮",
    "Signia By Hilton",
    "希尔顿",
    "尊盛",
    "Tempo",
    "希尔顿逸林",
    "希尔顿启缤精选",
    "希尔顿花园",
    "惠庭",
    "Motto",
    "希尔顿欢朋",
    "欣庭",
    "Tru",
    "希尔顿度假",
    "道玺",
    "温德姆至尊",
    "温德姆",
    "温德姆花园",
    "蔚景温德姆",
    "爵怡温德姆",
    "拉昆塔温德姆",
    "华美达广场",
    "戴斯广场",
    "华美达",
    "豪生",
    "戴斯酒店",
    "浩枫温德姆",
    "麦客达",
    "华美达安可",
    "戴斯精选",
    "速8",
    "宝丽嘉",
    "钓鱼台",
    "美高梅",
    "美高梅美幻",
    "美高梅美荟",
    "美高梅麦思精选",
    "百乐",
    "君乐",
    "Destination",
    "地中海俱乐部",
    "地中海白日方舟",
    "地中海邻境",
    "Club med exclusive collection",
    "都喜",
    "都喜泰丽",
    "都喜天丽",
    "ASAI",
    "都喜公主",
    "都喜酒店公寓",
    "瑰丽",
    "新世界",
    "君瑞",
    "君域",
    "君廷",
    "珺邸",
    "君廷行政公寓",
    "富喜坞",
    "邦臣",
    "邦臣嘉仕",
    "邦臣嘉选",
    "嘉佩乐",
    "柏典",
    "凯宾斯基",
    "诺金",
    "毕思图",
    "凯莱大饭店",
    "凯莱至尊酒店公寓",
    "凯莱",
    "凯莱度假",
    "凯莱酒店公寓",
    "凯莱逸郡",
    "凯莱悦享",
    "丽世",
    "塔玛莎",
    "盐选",
    "Socio",
    "朗廷",
    "康得思",
    "逸东",
    "盛美利亚",
    "美利亚豪华甄选",
    "ME",
    "帕拉提苏",
    "美利亚",
    "ZEL",
    "怡思得",
    "猎鹰度假",
    "SOL",
    "Affiliated",
    "比尔特莫",
    "千禧",
    "千禧大酒店",
    "STUDIO M",
    "M Social",
    "国敦",
    "Leng's Collection",
    "瑞斯丽铂",
    "瑞斯丽",
    "瑞斯嘉",
    "瑞斯",
    "瑞斯丽最独特精品酒店珍藏集",
    "托尼洛·兰博基尼",
    "托尼洛·兰博基尼ROSSO",
    "香格里拉",
    "嘉里",
    "盛茂",
    "JEN今旅",
    "雅阁大酒店",
    "雅阁璞邸",
    "雅阁度假",
    "雅阁",
    "雅阁欢聚",
    "雅阁公寓",
    "雅阁维福顿公寓",
    "澳斯特",
    "雅阁澳斯特",
    "澳斯特精选",
    "澳斯特公寓",
    "澳斯特WOW",
    "METRO",
    "卓美亚",
    "Zabeel House",
    "安缦",
    "半岛",
    "四季",
    "文华东方",
    "施伯阁大观",
    "施伯阁保时捷设计酒店",
    "宋品",
    "施柏阁",
    "禧玥",
    "花间堂",
    "永乐半山",
    "美仑美奂",
    "美仑国际",
    "城家奢华公寓",
    "城际",
    "漫心",
    "欢阁",
    "桔子水晶",
    "美仑",
    "城际服务公寓",
    "欢阁服务公寓",
    "馨乐庭公寓",
    "全季",
    "桔子",
    "星程",
    "城家高级公寓",
    "瑞贝庭公寓",
    "汉庭",
    "海友",
    "你好",
    "城家公寓",
    "励业公寓",
    "员宿",
    "万达瑞华",
    "万达文华",
    "万达嘉华",
    "万达锦华",
    "万达颐华",
    "万达美华",
    "J酒店",
    "岩花园",
    "昆仑",
    "丽笙精选",
    "锦江",
    "丽笙",
    "暻阁",
    "丽芮",
    "丽亭",
    "郁锦香",
    "丽筠",
    "丽祺",
    "好眠国际",
    "锦江都城",
    "丽柏",
    "缤跃",
    "维纳斯国际",
    "维纳斯皇家",
    "维也纳国际",
    "枫渡",
    "憬黎",
    "陶瑞",
    "舒与",
    "荟语",
    "希岸DELUXE",
    "ZMAX",
    "原拓",
    "云居",
    "丽怡",
    "维也纳智好",
    "希岸",
    "凯里亚德",
    "康铂",
    "丽枫",
    "欧暇·地中海",
    "亦岚",
    "维也纳",
    "潮漫",
    "非繁城品",
    "喆啡",
    "IU",
    "白玉兰",
    "维也纳三好",
    "7天",
    "锦江之星",
    "派酒店",
    "安纳塔拉",
    "凤悦",
    "缇沃丽",
    "诺皓",
    "诺翰精选",
    "凤颐",
    "凤凰",
    "安凡尼",
    "凤悦假日",
    "悦涧里",
    "希尔顿惠庭",
    "诺翰",
    "凤悦轻尚",
    "凤祺服务式公寓",
    "盛橡",
    "开元名都",
    "开元名庭",
    "方外",
    "观堂",
    "芳草地度假酒店",
    "CANARY加纳利",
    "Ruby瑰宝",
    "曼居",
    "颐居",
    "MORA SPACE穆拉",
    "建国饭店",
    "京伦饭店",
    "首旅南苑",
    "建国铂萃",
    "南山休闲会馆",
    "和颐至尊",
    "和颐至尚",
    "璞隐",
    "扉缦",
    "嘉虹",
    "万信至格",
    "如家精选",
    "如家商旅",
    "艾扉",
    "柏丽艾尚",
    "云上四季·尚品",
    "YUNIK",
    "如家",
    "莫泰",
    "驿居",
    "云上四季",
    "雅客e家",
    "欣燕都",
    "如家小镇",
    "漫趣乐园",
    "诗柏云",
    "素柏云",
    "睿柏云",
    "派柏云",
    "云上四季·民宿",
    "华译",
    "华译精选",
    "华译UP",
    "格兰云天国际",
    "格兰云天",
    "沛芮",
    "格兰云天·阅",
    "金陵",
    "金陵嘉珑",
    "雷迪森颐墅",
    "雷迪森世嘉",
    "雷迪森维嘉",
    "蝶来",
    "雷迪森怿曼",
    "颐颂",
    "雷迪森度假",
    "雷迪森庄园",
    "雷迪森广场",
    "雷迪森",
    "丽呈华廷",
    "携程度假农庄",
    "茂御",
    "丽呈",
    "丽呈别院",
    "丽呈和一S",
    "璞砚",
    "世御",
    "季枫国际",
    "富士国际",
    "丽呈公寓",
    "丽呈睿轩",
    "丽呈东谷",
    "丽呈朗誉",
    "睿选尚品",
    "云睿",
    "吴先生的酒店",
    "西姆",
    "漫亭",
    "裸心堡",
    "裸心帆",
    "裸心岭",
    "裸心泊",
    "裸心谷",
    "裸心园",
    "雅致",
    "丽致",
    "美丽豪",
    "美豪",
    "怡致",
    "R hotel",
    "A.T.HOUSE",
    "亚朵S",
    "ZHOTEL",
    "亚朵",
    "亚朵X",
    "轻居酒店",
    "尼依格罗",
    "马哥孛罗",
    "玛珂",
    "粤海",
    "粤海国际",
    "安麓",
    "柏联",
    "涵碧楼",
    "隐居繁华",
    "隐居酒店",
    "隐居",
    "隐居乡宿",
    "前季",
    "君澜大饭店",
    "君澜度假",
    "夜泊君亭",
    "君亭设计酒店",
    "景澜",
    "景澜度假",
    "君亭",
    "景阑青棠",
    "深航国际",
    "鹏逸",
    "深航精品",
    "深航假日",
    "鹏雅",
    "鹏轩"
]