import { default as hotel_45viewerXlxMV9UPZxMeta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/hotel-viewer.vue?macro=true";
import { default as loginBvK7dnTJB8Meta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/login.vue?macro=true";
import { default as afterLoginn8SeCFRoqxMeta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/loginUtils/afterLogin.ts?macro=true";
import { default as getAuthorizationsiUdJH7QKxPMeta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/loginUtils/afterLoginUtils/getAuthorizations.ts?macro=true";
import { default as sendSMSTQJgsyUBlyMeta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/loginUtils/sendSMS.ts?macro=true";
import { default as SMSLoginRfa4qmOxwmMeta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/loginUtils/SMSLogin.ts?macro=true";
import { default as report_45viewergL8hrR7Vz8Meta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/report-viewer.vue?macro=true";
import { default as index4nbNIqtWtdMeta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/start/index.vue?macro=true";
import { default as siteConfigItkWhOOx2ZMeta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/start/startUtils/siteConfig.ts?macro=true";
import { default as _91_filePath_93ITayOVd1ejMeta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/[_filePath].vue?macro=true";
import { default as displayProjectsqp5C4fgakXMeta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/displayProjects.ts?macro=true";
import { default as getAdFiles3FEOdKUb8vMeta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/getAdFiles.ts?macro=true";
import { default as getCategory1OptionseSFCjUDBE3Meta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/getCategory1Options.ts?macro=true";
import { default as getPageContentsn4SRwa7ADMeta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/getPageContent.ts?macro=true";
import { default as getPageContentOverrideqcQX6yNHHtMeta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/getPageContentOverride.ts?macro=true";
import { default as huameiAnnualsaonyle4MV0Meta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/huameiAnnuals.ts?macro=true";
import { default as processLeftMenugkcv1HPIw2Meta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/processLeftMenu.ts?macro=true";
import { default as brandsC6GGc3QDWXMeta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/utils/brands.ts?macro=true";
import { default as citiesMQiIKfQCI7Meta } from "/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/utils/cities.ts?macro=true";
export default [
  {
    name: hotel_45viewerXlxMV9UPZxMeta?.name ?? "hotel-viewer",
    path: hotel_45viewerXlxMV9UPZxMeta?.path ?? "/hotel-viewer",
    meta: hotel_45viewerXlxMV9UPZxMeta || {},
    alias: hotel_45viewerXlxMV9UPZxMeta?.alias || [],
    redirect: hotel_45viewerXlxMV9UPZxMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/hotel-viewer.vue").then(m => m.default || m)
  },
  {
    name: loginBvK7dnTJB8Meta?.name ?? "login",
    path: loginBvK7dnTJB8Meta?.path ?? "/login",
    meta: loginBvK7dnTJB8Meta || {},
    alias: loginBvK7dnTJB8Meta?.alias || [],
    redirect: loginBvK7dnTJB8Meta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/login.vue").then(m => m.default || m)
  },
  {
    name: afterLoginn8SeCFRoqxMeta?.name ?? "loginUtils-afterLogin",
    path: afterLoginn8SeCFRoqxMeta?.path ?? "/loginUtils/afterLogin",
    meta: afterLoginn8SeCFRoqxMeta || {},
    alias: afterLoginn8SeCFRoqxMeta?.alias || [],
    redirect: afterLoginn8SeCFRoqxMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/loginUtils/afterLogin.ts").then(m => m.default || m)
  },
  {
    name: getAuthorizationsiUdJH7QKxPMeta?.name ?? "loginUtils-afterLoginUtils-getAuthorizations",
    path: getAuthorizationsiUdJH7QKxPMeta?.path ?? "/loginUtils/afterLoginUtils/getAuthorizations",
    meta: getAuthorizationsiUdJH7QKxPMeta || {},
    alias: getAuthorizationsiUdJH7QKxPMeta?.alias || [],
    redirect: getAuthorizationsiUdJH7QKxPMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/loginUtils/afterLoginUtils/getAuthorizations.ts").then(m => m.default || m)
  },
  {
    name: sendSMSTQJgsyUBlyMeta?.name ?? "loginUtils-sendSMS",
    path: sendSMSTQJgsyUBlyMeta?.path ?? "/loginUtils/sendSMS",
    meta: sendSMSTQJgsyUBlyMeta || {},
    alias: sendSMSTQJgsyUBlyMeta?.alias || [],
    redirect: sendSMSTQJgsyUBlyMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/loginUtils/sendSMS.ts").then(m => m.default || m)
  },
  {
    name: SMSLoginRfa4qmOxwmMeta?.name ?? "loginUtils-SMSLogin",
    path: SMSLoginRfa4qmOxwmMeta?.path ?? "/loginUtils/SMSLogin",
    meta: SMSLoginRfa4qmOxwmMeta || {},
    alias: SMSLoginRfa4qmOxwmMeta?.alias || [],
    redirect: SMSLoginRfa4qmOxwmMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/loginUtils/SMSLogin.ts").then(m => m.default || m)
  },
  {
    name: report_45viewergL8hrR7Vz8Meta?.name ?? "report-viewer",
    path: report_45viewergL8hrR7Vz8Meta?.path ?? "/report-viewer",
    meta: report_45viewergL8hrR7Vz8Meta || {},
    alias: report_45viewergL8hrR7Vz8Meta?.alias || [],
    redirect: report_45viewergL8hrR7Vz8Meta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/report-viewer.vue").then(m => m.default || m)
  },
  {
    name: index4nbNIqtWtdMeta?.name ?? "start",
    path: index4nbNIqtWtdMeta?.path ?? "/start",
    meta: index4nbNIqtWtdMeta || {},
    alias: index4nbNIqtWtdMeta?.alias || [],
    redirect: index4nbNIqtWtdMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/start/index.vue").then(m => m.default || m)
  },
  {
    name: siteConfigItkWhOOx2ZMeta?.name ?? "start-startUtils-siteConfig",
    path: siteConfigItkWhOOx2ZMeta?.path ?? "/start/startUtils/siteConfig",
    meta: siteConfigItkWhOOx2ZMeta || {},
    alias: siteConfigItkWhOOx2ZMeta?.alias || [],
    redirect: siteConfigItkWhOOx2ZMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/start/startUtils/siteConfig.ts").then(m => m.default || m)
  },
  {
    name: _91_filePath_93ITayOVd1ejMeta?.name ?? "v3-_filePath",
    path: _91_filePath_93ITayOVd1ejMeta?.path ?? "/v3/:_filePath()",
    meta: _91_filePath_93ITayOVd1ejMeta || {},
    alias: _91_filePath_93ITayOVd1ejMeta?.alias || [],
    redirect: _91_filePath_93ITayOVd1ejMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/[_filePath].vue").then(m => m.default || m)
  },
  {
    name: displayProjectsqp5C4fgakXMeta?.name ?? "v3-v3Utils-displayProjects",
    path: displayProjectsqp5C4fgakXMeta?.path ?? "/v3/v3Utils/displayProjects",
    meta: displayProjectsqp5C4fgakXMeta || {},
    alias: displayProjectsqp5C4fgakXMeta?.alias || [],
    redirect: displayProjectsqp5C4fgakXMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/displayProjects.ts").then(m => m.default || m)
  },
  {
    name: getAdFiles3FEOdKUb8vMeta?.name ?? "v3-v3Utils-getAdFiles",
    path: getAdFiles3FEOdKUb8vMeta?.path ?? "/v3/v3Utils/getAdFiles",
    meta: getAdFiles3FEOdKUb8vMeta || {},
    alias: getAdFiles3FEOdKUb8vMeta?.alias || [],
    redirect: getAdFiles3FEOdKUb8vMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/getAdFiles.ts").then(m => m.default || m)
  },
  {
    name: getCategory1OptionseSFCjUDBE3Meta?.name ?? "v3-v3Utils-getCategory1Options",
    path: getCategory1OptionseSFCjUDBE3Meta?.path ?? "/v3/v3Utils/getCategory1Options",
    meta: getCategory1OptionseSFCjUDBE3Meta || {},
    alias: getCategory1OptionseSFCjUDBE3Meta?.alias || [],
    redirect: getCategory1OptionseSFCjUDBE3Meta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/getCategory1Options.ts").then(m => m.default || m)
  },
  {
    name: getPageContentsn4SRwa7ADMeta?.name ?? "v3-v3Utils-getPageContent",
    path: getPageContentsn4SRwa7ADMeta?.path ?? "/v3/v3Utils/getPageContent",
    meta: getPageContentsn4SRwa7ADMeta || {},
    alias: getPageContentsn4SRwa7ADMeta?.alias || [],
    redirect: getPageContentsn4SRwa7ADMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/getPageContent.ts").then(m => m.default || m)
  },
  {
    name: getPageContentOverrideqcQX6yNHHtMeta?.name ?? "v3-v3Utils-getPageContentOverride",
    path: getPageContentOverrideqcQX6yNHHtMeta?.path ?? "/v3/v3Utils/getPageContentOverride",
    meta: getPageContentOverrideqcQX6yNHHtMeta || {},
    alias: getPageContentOverrideqcQX6yNHHtMeta?.alias || [],
    redirect: getPageContentOverrideqcQX6yNHHtMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/getPageContentOverride.ts").then(m => m.default || m)
  },
  {
    name: huameiAnnualsaonyle4MV0Meta?.name ?? "v3-v3Utils-huameiAnnuals",
    path: huameiAnnualsaonyle4MV0Meta?.path ?? "/v3/v3Utils/huameiAnnuals",
    meta: huameiAnnualsaonyle4MV0Meta || {},
    alias: huameiAnnualsaonyle4MV0Meta?.alias || [],
    redirect: huameiAnnualsaonyle4MV0Meta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/huameiAnnuals.ts").then(m => m.default || m)
  },
  {
    name: processLeftMenugkcv1HPIw2Meta?.name ?? "v3-v3Utils-processLeftMenu",
    path: processLeftMenugkcv1HPIw2Meta?.path ?? "/v3/v3Utils/processLeftMenu",
    meta: processLeftMenugkcv1HPIw2Meta || {},
    alias: processLeftMenugkcv1HPIw2Meta?.alias || [],
    redirect: processLeftMenugkcv1HPIw2Meta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/processLeftMenu.ts").then(m => m.default || m)
  },
  {
    name: brandsC6GGc3QDWXMeta?.name ?? "v3-v3Utils-utils-brands",
    path: brandsC6GGc3QDWXMeta?.path ?? "/v3/v3Utils/utils/brands",
    meta: brandsC6GGc3QDWXMeta || {},
    alias: brandsC6GGc3QDWXMeta?.alias || [],
    redirect: brandsC6GGc3QDWXMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/utils/brands.ts").then(m => m.default || m)
  },
  {
    name: citiesMQiIKfQCI7Meta?.name ?? "v3-v3Utils-utils-cities",
    path: citiesMQiIKfQCI7Meta?.path ?? "/v3/v3Utils/utils/cities",
    meta: citiesMQiIKfQCI7Meta || {},
    alias: citiesMQiIKfQCI7Meta?.alias || [],
    redirect: citiesMQiIKfQCI7Meta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/hm_cloud/pages/v3/v3Utils/utils/cities.ts").then(m => m.default || m)
  }
]