import { removeStorage } from "dingtalk-jsapi/api/apiObj"

export default defineNuxtRouteMiddleware(async (to, from) => {
    const currOrgStore = useCookie("currOrg")
    const userInfoStore = {} as any


    const toOrganization = (userInfoStore.info||{}).organization || to.query.organization
    switch (to.path) {
        case ('/'):{
            if(["华美员工","华美管理"].find(x=>userInfoStore.info.user_type_multi.includes(x))){
                return navigateTo({
                    path:'/choose-category',
                    query:{
                        ...to.query,
                        organization:toOrganization
                    }
                })
            }else if(userInfoStore.info.user_type_multi.includes("酒管集团")){
                return navigateTo({
                    path:`/home-pages/management-group/brand?organization=${toOrganization}`,
                    query:{
                        ...to.query,
                        organization:toOrganization
                    }
                })
            }else if(userInfoStore.info.user_type_multi.includes("项目主页客户")){
                return navigateTo({
                    path: `/home-pages/project/project?organization=${toOrganization}`,
                    query:{
                        ...to.query,
                        organization:toOrganization
                    }
                })
            }else if(userInfoStore.info.user_type_multi.includes("设计主页客户")){
                return navigateTo({
                    path: `/navigate`,
                    query:{
                        ...to.query,
                        page_type:'设计主页',
                        organization:toOrganization
                    }
                })
            }else if(userInfoStore.info.user_type_multi.includes("采购主页客户")){
                return navigateTo({
                    path: `/navigate`,
                    query:{
                        ...to.query,
                        page_type:'采购主页',
                        organization:toOrganization
                    }
                })
            }else if(userInfoStore.info.user_type_multi.includes("酒店业主")){
                return navigateTo({
                    path:`/home-pages/ownership/industry?organization=${toOrganization}`,
                    query:{
                        ...to.query,
                        organization:toOrganization
                    }
                })
            }else if(userInfoStore.info.user_type_multi.includes("酒管院校")){
                return navigateTo({
                    path:`/home-pages/school/industry?organization=${toOrganization}`,
                    query:{
                        ...to.query,
                        organization:toOrganization
                    }
                })
            }else if(userInfoStore.info.user_type_multi.includes("单体酒店")){
                return navigateTo({
                    path:`/home-pages/hotel/company?organization=${toOrganization}`,
                    query:{
                        ...to.query,
                        organization:toOrganization
                    }
                })
            }else if(userInfoStore.info.user_type_multi.includes("行业协会")){
                return navigateTo({
                    path:`/home-pages/association/industry?organization=${toOrganization}`,
                    query:{
                        ...to.query,
                        organization:toOrganization
                    }
                })
            }
            break
        }
        case ('/login'):
        case ('/manage-users'):
        case ('/manage-projects'):
        case ('/choose-category'):
        {
            currOrgStore.value = ''

            if(to.query.organization){
                return navigateTo({
                    path: to.path, 
                    query: {
                        ...to.query,
                        organization:''
                    }
                })
            }else{
                return
            }
        }
        default: {
        }
    }

})
  