import { pinyinSort } from "~/utils/pinyinSort";

const topCities = ["北京","上海","深圳","广州"]
export default [
    "北京",
    "上海",
    "广州",
    "深圳",
    "七台河",
    "万宁",
    "三亚",
    "三明",
    "三沙",
    "三门峡",
    "上饶",
    "东方",
    "东莞",
    "东营",
    "丽水",
    "丽江",
    "中卫",
    "中山",
    "临夏",
    "临夏自治州",
    "临汾",
    "临沂",
    "临沧",
    "丹东",
    "乌兰察布",
    "乌海",
    "乌鲁木齐",
    "乐东",
    "乐东自治县",
    "乐山",
    "九江",
    "云浮",
    "五指山",
    "亳州",
    "仙桃",
    "伊春",
    "伊犁",
    "伊犁自治州",
    "佛山",
    "佳木斯",
    "保亭",
    "保亭自治县",
    "保定",
    "保山",
    "信阳",
    "儋州",
    "克孜勒苏",
    "克孜勒苏自治州",
    "克拉玛依",
    "六安",
    "六盘水",
    "兰州",
    "兴安",
    "兴安盟",
    "内江",
    "凉山",
    "凉山自治州",
    "包头",
    "北屯",
    "北海",
    "十堰",
    "南京",
    "南充",
    "南宁",
    "南平",
    "南昌",
    "南通",
    "南阳",
    "博尔塔拉",
    "博尔塔拉自治州",
    "厦门",
    "双河",
    "双鸭山",
    "可克达拉",
    "台州",
    "合肥",
    "吉安",
    "吉林",
    "吐鲁番",
    "吕梁",
    "吴忠",
    "周口",
    "呼伦贝尔",
    "呼和浩特",
    "和田",
    "和田地区",
    "咸宁",
    "咸阳",
    "哈密",
    "哈尔滨",
    "唐山",
    "商丘",
    "商洛",
    "喀什",
    "喀什地区",
    "嘉兴",
    "嘉峪关",
    "四平",
    "固原",
    "图木舒克",
    "塔城",
    "大兴安岭",
    "大同",
    "大庆",
    "大理",
    "大理自治州",
    "大连",
    "天水",
    "天津",
    "天门",
    "太原",
    "威海",
    "孝感",
    "宁德",
    "宁波",
    "安庆",
    "安康",
    "安阳",
    "安顺",
    "定安",
    "定安县",
    "定西",
    "宜宾",
    "宜昌",
    "宜春",
    "宝鸡",
    "宣城",
    "宿州",
    "宿迁",
    "屯昌",
    "山南",
    "岳阳",
    "崇左",
    "巴中",
    "巴彦淖尔",
    "巴音郭楞",
    "巴音郭楞自治州",
    "常州",
    "常德",
    "平凉",
    "平顶山",
    "广元",
    "广安",
    "庆阳",
    "廊坊",
    "延安",
    "延边",
    "延边自治州",
    "开封",
    "张家口",
    "张家界",
    "张掖",
    "徐州",
    "德宏",
    "德宏自治州",
    "德州",
    "德阳",
    "忻州",
    "怀化",
    "怒江",
    "怒江自治州",
    "恩施",
    "恩施自治州",
    "惠州",
    "成都",
    "扬州",
    "承德",
    "抚州",
    "抚顺",
    "拉萨",
    "揭阳",
    "攀枝花",
    "文山",
    "文山自治州",
    "文昌",
    "新余",
    "新乡",
    "新星",
    "无锡",
    "日喀则",
    "日照",
    "昆明",
    "昌吉",
    "昌吉自治州",
    "昌江",
    "昌江自治县",
    "昌都",
    "昭通",
    "晋中",
    "晋城",
    "普洱",
    "景德镇",
    "曲靖",
    "朔州",
    "朝阳",
    "本溪",
    "来宾",
    "杭州",
    "松原",
    "林芝",
    "果洛",
    "枣庄",
    "柳州",
    "株洲",
    "桂林",
    "梅州",
    "梧州",
    "楚雄",
    "楚雄自治州",
    "榆林",
    "武威",
    "武汉",
    "毕节",
    "永州",
    "汉中",
    "汕头",
    "汕尾",
    "江门",
    "池州",
    "沈阳",
    "沧州",
    "河池",
    "河源",
    "泉州",
    "泰安",
    "泰州",
    "泸州",
    "洛阳",
    "济南",
    "济宁",
    "济源",
    "海东",
    "海北",
    "海南",
    "海口",
    "海西",
    "海西自治州",
    "淄博",
    "淮北",
    "淮南",
    "淮安",
    "清远",
    "温州",
    "渭南",
    "湖州",
    "湘潭",
    "湘西",
    "湘西自治州",
    "湛江",
    "滁州",
    "滨州",
    "漯河",
    "漳州",
    "潍坊",
    "潜江",
    "潮州",
    "澄迈",
    "澄迈县",
    "濮阳",
    "烟台",
    "焦作",
    "牡丹江",
    "玉林",
    "玉树自治州",
    "玉溪",
    "珠海",
    "琼中",
    "琼中自治县",
    "琼海",
    "甘南",
    "甘孜",
    "甘孜自治州",
    "白城",
    "白山",
    "白银",
    "百色",
    "益阳",
    "盐城",
    "盘锦",
    "眉山",
    "石嘴山",
    "石家庄",
    "石河子",
    "神农架",
    "神农架林区",
    "福州",
    "秦皇岛",
    "娄底",
    "红河",
    "红河自治州",
    "绍兴",
    "绥化",
    "绵阳",
    "聊城",
    "肇庆",
    "胡杨河",
    "自贡",
    "舟山",
    "芜湖",
    "苏州",
    "茂名",
    "荆州",
    "荆门",
    "莆田",
    "菏泽",
    "萍乡",
    "营口",
    "葫芦岛",
    "蚌埠",
    "衡水",
    "衡阳",
    "衢州",
    "襄阳",
    "西双版纳",
    "西双版纳自治州",
    "西宁",
    "西安",
    "许昌",
    "贵港",
    "贵阳",
    "贺州",
    "资阳",
    "赣州",
    "赤峰",
    "辽源",
    "辽阳",
    "达州",
    "运城",
    "连云港",
    "迪庆",
    "迪庆自治州",
    "通化",
    "通辽",
    "遂宁",
    "遵义",
    "邢台",
    "那曲",
    "邯郸",
    "邵阳",
    "郑州",
    "郴州",
    "鄂尔多斯",
    "鄂州",
    "酒泉",
    "重庆",
    "金华",
    "金昌",
    "钦州",
    "铁岭",
    "铜仁",
    "铜川",
    "铜陵",
    "银川",
    "锡林郭勒",
    "锡林郭勒盟",
    "锦州",
    "镇江",
    "长春",
    "长沙",
    "长治",
    "阜新",
    "阜阳",
    "防城港",
    "阳江",
    "阳泉",
    "阿克苏",
    "阿克苏地区",
    "阿勒泰",
    "阿勒泰地区",
    "阿坝",
    "阿坝自治州",
    "阿拉善",
    "阿拉尔",
    "阿里",
    "陇南",
    "陵水",
    "陵水自治县",
    "随州",
    "雅安",
    "青岛",
    "鞍山",
    "韶关",
    "马鞍山",
    "驻马店",
    "鸡西",
    "鹤壁",
    "鹤岗",
    "鹰潭",
    "黄冈",
    "黄南",
    "黄山",
    "黄石",
    "黑河",
    "黔东南",
    "黔东南自治州",
    "黔南",
    "黔南自治州",
    "黔西南",
    "黔西南自治州",
    "齐齐哈尔",
    "龙岩"
].sort((a, b) =>{
    if(topCities.includes(a) && !topCities.includes(b)){
        return -1
    }
    if(topCities.includes(b) && !topCities.includes(a)){
        return 1
    }
    if(topCities.includes(b) && topCities.includes(a)){
        return topCities.indexOf(a) - topCities.indexOf(b)
    }
    return pinyinSort(a, b)
})